<template>
    <div class="vendor-vacation-edit">
        <edit :fs="vacationFields" api="vacations" :id="id" title="Vacation" @saved="saved"></edit>
    </div>
</template>
<script>
import Edit from "@/components/Edit";
import { vacationFields } from "@/config";
export default {
    props: ["id"],
    components: {
        Edit
    },
    computed: {
        vacationFields() {
            return vacationFields.filter(field => field.heading != "Vendor");
        }
    },
    methods: {
        saved() {
            this.$router.push("/admin/vacations");
        }
    }
};
</script>