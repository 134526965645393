<template>
    <div class="vendor-vacation-list">
        <list :apiList="apiList" :fs="vacationFields" addLink="/admin/vacations/new" api="vacations" editLink="/admin/vacations" title="Vacations"></list>
    </div>
</template>
<script>
import List from "@/components/List";
import { vacationFields } from "@/config";
import { mapGetters } from "vuex";
export default {
    components: {
        List
    },
    computed: {
        ...mapGetters({
            hasRole: "user/hasRole"
        }),
        apiList() {
            if (this.hasRole("manager")) {
                return "vacations";
            } else {
                return "vacations/my";
            }
        },
        vacationFields() {
            if (this.hasRole("manager")) {
                return vacationFields;
            } else {
                return vacationFields.filter(field => field.heading != "Vendor");
            }
        }
    }
};
</script>