<template>
    <div class="vacation-index">
        <md-tabs @change="tabChange" ref="tabs">
            <md-tab :md-active="tabIndex==0" md-label="Vacations">
                <vendor-vacation-list ref="list" v-if="tabIndex==0"></vendor-vacation-list>
            </md-tab>
            <md-tab :md-active="tabIndex==1" md-label="Add Vacation">
                <vendor-vacation-edit ref="add" v-if="tabIndex==1"></vendor-vacation-edit>
            </md-tab>
            <md-tab :md-active="tabIndex==2" md-label="Edit Vacation" v-if="tabIndex==2">
                <vendor-vacation-edit :id="id" ref="edit" v-if="tabIndex==2"></vendor-vacation-edit>
            </md-tab>
        </md-tabs>
    </div>
</template>


<script>
import VendorVacationList from "@/components/Vendor/VendorVacationList";
import VendorVacationEdit from "@/components/Vendor/VendorVacationEdit";
export default {
    props: ["id"],
    components: {
        VendorVacationList,
        VendorVacationEdit
    },
    data() {
        return {
            tabIndex: 0,
            tabChangeCount: 0
        };
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                console.info("id", this.id);
                if (!this.id) {
                    this.tabIndex = 0;
                } else if (this.id == "new") {
                    this.tabIndex = 1;
                } else {
                    this.tabIndex = 2;
                }
            }
        }
    },
    methods: {
        tabChange(index) {
            if (this.tabChangeCount) {
                if (index == 0) {
                    this.$router.push("/admin/vacations");
                }
                if (index == 1) {
                    this.$router.push("/admin/vacations/new");
                }
                if (index == 2) {
                    this.$router.push(`/admin/vacations/${this.id}`);
                }
            }
            this.tabChangeCount++;
        }
    }
};
</script>